import React, { useContext, useEffect } from 'react'
import TrendingBlock from '../../components/TrendingBlock'
import RecentProductsPreview from '../../components/RecentProductsPreview'
import SavedProductsPreview from '../../components/SavedProductsPreview'
import SalesBanner from '../../components/SalesBanner'
import VendorsBanner from '../../components/VendorsBanner'
import IndexSocial from '../../components/IndexSocial'
import HomeTopSection from '../../components/HomeTopSection'
import HomeTopSectionBF from '../../components/HomeTopSection/HomeTopSectionBF'
import BusinessEntryPoint from '../../components/BusinessEntryPoint'
import YouMayAlsoLike from '../../components/YouMayAlsoLike'
import TrendingSearches from '../../components/TrendingSearches'
import VintageProductsPreview from '../../components/VintageProductsPreview'
import CustomMerchPreview from '../../components/CustomMerchPreview'
import Feedback from '../../components/Feedback'
import AppContext from '../../helpers/AppContext'
import theme from '../../theming'
import ShopByGender from '../../components/ShopByGender'
import { useLocation } from 'react-router-dom'
import SignUpProposal from '../../components/SignUpProposal'

const Home: React.FC = () => {
    const ctx = useContext(AppContext)

    const search = new URLSearchParams(useLocation().search || '')
    const shopBy = search.get('shop_by')

    return (
        <>
            <HomeTopSection />
            {/* <div style={{ maxWidth: '1030px', margin: '0 auto' }}>
                <ShopByGender shopByGender={shopBy || ''} />
                <TrendingBlock shopByGender={shopBy || ''} />
            </div> */}
            {/* <SalesBanner shopByGender={shopBy || ''} /> */}
            <div style={{ maxWidth: '1030px', margin: '0 auto' }}>
                <VintageProductsPreview />
                <CustomMerchPreview />
                <SignUpProposal />
                <RecentProductsPreview />
                <SavedProductsPreview />
                {/* <VendorsBanner shopByGender={shopBy || ''} /> */}
                <YouMayAlsoLike />
                <TrendingSearches />
                <Feedback />
                <IndexSocial />
            </div>
        </>
    )
}

export default Home

import React, { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { getCustomMerchProds, MmApiProduct } from '../../helpers/apiClient'
import AppContext from '../../helpers/AppContext'
import { useTranslate } from '../../helpers/useTranslate'
import Section, { SectionControl } from '../Section/Section'
import TextLine from '../TextLine'

interface ProductCardProps {
    product: MmApiProduct
}

const trackClickAndOpenNewTab = (prodId: string) => {
    window.open(`https://ai-merchmaker.vercel.app/products/${prodId}`, '_blank')
    ReactGA.event({
        category: 'User',
        action: 'Clicked on merch products'
    })
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => (
    <div
        style={{
            width: '245px',
            maxWidth: 'calc(50% - 8px)',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.14)',
            cursor: 'pointer'
        }}
        onClick={() => trackClickAndOpenNewTab(product.id)}
    >
        <img
            style={{ width: '100%' }}
            src={product.image_url}
            alt={product.name}
        />
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                padding: '0 1rem'
            }}
        >
            <div>
                <p style={{ color: 'rgba(0,0,0,.55)', fontSize: '15px' }}>
                    <TextLine text="Prompt: " type="small" />
                    <span
                        style={{
                            fontFamily: 'monospace',
                            fontWeight: 'bold',
                            fontSize: '0.875rem'
                        }}
                    >
                        <TextLine
                            text={
                                product.design_spec
                                    ? `${product.design_spec.user_prompt.substring(
                                          0,
                                          23
                                      )}${
                                          product.design_spec.user_prompt
                                              .length > 23
                                              ? '...'
                                              : ''
                                      }`
                                    : ''
                            }
                            type="small"
                        />
                    </span>
                </p>
            </div>
            <div>
                <span
                    style={{
                        display: 'inline-block',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        marginBottom: '0.5rem'
                    }}
                >
                    <TextLine bold text={`$ ${product.price}`} type="small" />
                </span>
            </div>
        </div>
    </div>
)

export interface Props extends RouteComponentProps {
    className?: string
}

const CustomMerchPreview: React.FC<Props> = (props) => {
    const { className } = props
    const { translate } = useTranslate()
    const ctx = useContext(AppContext)
    const [products, setProducts] = useState<MmApiProduct[]>([])

    const makeYours = (props: Props) => {
        ReactGA.event({
            category: 'User',
            action: `Clicked 'make your own'`
        })
        props.history.push('/merch-maker')
    }

    useEffect(() => {
        getCustomMerchProds()
            .then((res: MmApiProduct[]) => {
                setProducts(res)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const productList = (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px'
            }}
        >
            {products.map((product: MmApiProduct) => (
                <ProductCard key={product.id} product={product} />
            ))}
        </div>
    )
    const sectionControls: SectionControl[] = []
    const control = {
        content: (
            <TextLine
                className="subtitle"
                text={'Make your own'}
                type="small"
                underline={true}
                inline={true}
            />
        ),
        onClick: () => {
            makeYours(props)
        }
    }

    if (ctx.isDesktop) {
        sectionControls.push(control)
    }

    const section = products.length ? (
        <Section
            title={'Custom AI Generated T-Shirts'}
            subtitle={
                'Design your own unique t-shirt with our AI Merch Maker tool.'
            }
            body={productList}
            controls={sectionControls}
            bottomCTA={ctx.isDesktop ? undefined : control}
        />
    ) : null

    return <div className={className}>{section}</div>
}

const StyledCustomMerchPreview = styled(CustomMerchPreview)((props) => {
    return {
        minHeight: '350px'
    }
})

export default withRouter(StyledCustomMerchPreview)
